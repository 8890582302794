import * as React from "react";
import { tokenize,render } from "micromustache";
import { Card, Text,LoadingOverlay,ScrollArea } from '@mantine/core';
import { DirectusContext } from "../../../../Provider/Directus";
import PanelCard from "./PanelCard";

export default class List extends React.Component {
  
  static contextType = DirectusContext
  state={loading:true,data:[]}

  async componentDidMount() {
    let query = this.query(this.props.options)
    const {data} = await this.context.directus.items(query.collection).readByQuery(query.query)
    this.setState({data,loading:false})
  }
  
  
	query(options) {
		if (!options?.collection) return;
		const displayFields = tokenize(options.displayTemplate).varNames;
		const sort = options.sortField;

		return {
			collection: options.collection,
			query: {
				filter: options.filter ?? {},
				fields: displayFields,
				sort: !options.sortDirection || options.sortDirection === 'desc' ? `-${sort}` : sort,
				limit: options.limit === undefined ? 5 : options.limit,
			},
		};
	}

  render() {
    if(this.state.loading) return <LoadingOverlay visible overlayBlur={2} />
    return (
      <PanelCard {...this.props}>
        <ScrollArea offsetScrollbars sx={{height:"100%",width:"100%",padding:0,margin:0}}>
          {this.state.data.map(el=><div style={{padding:"10px", borderBottom:"1px solid rgba(255,255,255,0.1)"}}>
            <Text size="lg">{render(this.props.options.displayTemplate,el)}</Text>
          </div>)}
        </ScrollArea>
      </PanelCard>
    );
  }
}

List.defaultProps = {
  "id": "bb6d1638-eb9c-47a4-ae92-f049366b02be",
  "dashboard": "7adad88d-f981-4213-a9d4-a69f405c94e5",
  "name": "Fälle",
  "icon": "forum",
  "color": null,
  "show_header": true,
  "note": null,
  "type": "metric",
  "position_x": 29,
  "position_y": 1,
  "width": 16,
  "height": 6,
  "options": {
      "collection": "cases",
      "field": "id",
      "function": "count"
  },
}