import { Alert, Group, TextInput } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import DefaultInput from './DefaultInput';
import moment from 'moment';
import { IconAlertCircle } from '@tabler/icons';
const locale_de = require('dayjs/locale/de');

export default class DatetimeDisplay extends DefaultInput {
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),
      placeholder: props.field.meta.options?.placeholder,
      description: props.field.meta.note,
      required: props.field.meta.required,
      disabled: props.field.meta.readonly
    };
    if (props.field.type === 'date') {
      return (
        <DatePicker
          icon={<span className='material-icons' style={{ fontSize: 20 }}>calendar_month</span>}
          inputFormat='DD.MM.YYYY'
          labelFormat='YYYY-MM-DD'
          {...inputProps}
          value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? new Date(props.field.schema.default_value) : '') : ''}
          onChange={(value) => this.context.onChange(props.field.field, value)}
          {...this.props.field.options}
        />
      );
    }
    if (props.field.type === 'time') {
      const value = this.context.values[props.field.field]?.split(':');
      return (
        <TimeInput
          clearable
          icon={<span className='material-icons' style={{ fontSize: 20 }}>access_time</span>}
          {...inputProps}
          value={value ? (value.length == 2 ? moment().hours(value[0]).minutes(value[1]).toDate() : moment().hours(value[0]).minutes(value[1]).seconds(value[2]).toDate()) : undefined}
          onChange={(value) => this.context.onChange(props.field.field, moment(value).format('HH:mm'))}
          {...this.props.field.options}
        />
      );
    }
    if (props.field.type === 'dateTime') {
      const value = this.context.values[props.field.field];
      return (
        <Group grow>
          <DatePicker
            icon={<span className='material-icons' style={{ fontSize: 20 }}>calendar_month</span>}
            inputFormat='DD.MM.YYYY'
            labelFormat='YYYY-MM-DD'
            {...inputProps}
            value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? new Date(props.field.schema.default_value) : '') : this.context.values[props.field.field]}
            onChange={(value) => {
              const time = this.context.values[props.field.field] ? moment(this.context.values[props.field.field]) : moment();
              const valueM = moment(value);
              time.year(valueM.year()).month(valueM.month()).date(valueM.date());
              this.context.onChange(props.field.field, time.toDate());
            }}
            {...this.props.field.options}
          />
          <TimeInput
            clearable
            icon={<span className='material-icons' style={{ fontSize: 20 }}>access_time</span>}
            {...inputProps}
            label=' '
            value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? new Date(props.field.schema.default_value) : undefined) : this.context.values[props.field.field]}
            onChange={(value) => {
              const time = this.context.values[props.field.field] ? moment(this.context.values[props.field.field]) : moment();
              const valueM = moment(value);
              time.hours(valueM.hours()).minutes(valueM.minutes()).seconds(valueM.seconds());
              this.context.onChange(props.field.field, time.toDate());
            }}
            {...this.props.field.options}
          />
        </Group>
      );
    }
    return (
      <Alert style={{ padding: '5px 5px 0 10px', marginTop: 15 }} key={props.field.field} variant='outline' color='yellow' icon={<IconAlertCircle size={16} />} title={`Feld-Typ wird nicht unterstützt! (${props.field.type})`} />
    );
  }
}
