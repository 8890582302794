import * as React from "react";
import { Button, Alert, Grid, LoadingOverlay, Modal, createEmotionCache } from '@mantine/core';
import { DirectusContext } from '../../Provider/Directus';
import Displays from './Interfaces';

import {FieldSetContext} from '../../Contexts/DirectusFieldSet'

import { IconAlertCircle } from '@tabler/icons';



export default class FieldSet extends React.Component {
  static contextType = DirectusContext
  state={
    dashboard: {
      name: "Loading"
    },
    error:false,
    errorMessage: "",
    loading: true,
    values:{}
  }
  async componentDidMount(){
    await this.update()
    this.setState({values: this.props.values})
  }
  async update(){
    this.setState({ loading: true })
    try{
      const collection = await this.context.directus.items("directus_collections").readOne(this.props.collection);
      let {data:fieldsRaw} = await this.context.directus.items("directus_fields").readByQuery({
        filter: {collection: { _eq: this.props.collection } }
      });
      const fields = this.calulateGroups(fieldsRaw.filter(el=>el.collection==this.props.collection))
      this.setState({ loading: false,collection,fields,fieldsRaw})
    }catch(e){
      this.setState({loading:false, error:true, errorMessage:e.message})
    }
  }

  calulateGroups(fields){
    let groups = new Map();
    for(let field of fields){
      if(!groups.has(field.meta.group)) groups.set(field.meta.group,[]);
      groups.get(field.meta.group).push({...field});
    }
    for(let [group, fields] of groups){
        fields.sort((a,b)=>{
          if (a.meta.sort === null) {
            return 1;
        }
        if (b.meta.sort === null) {
            return -1;
        }
        return a.meta.sort-b.meta.sort
      });
    }
    let hasBeenProcessed = new Map();
    function attachItems(field){
      if(groups.has(field.field)){
        field.items = groups.get(field.field);
      }
    }
    for(let [field,fields] of groups){
      for(let field of fields) attachItems(field);
    }
    return groups.get(null)
  }

  validateFields(){
    let valid = false
    //TODO VALIDATE ENTRY
    valid = true
    this.props.onChange(this.state.values,valid)
  }


  render() {
    if(this.state.error) return  (<Alert icon={<IconAlertCircle size={16} />} title="Fehler!" color="red">
      {this.state.errorMessage}
    </Alert>)
    if(this.state.loading) return  (<LoadingOverlay visible />)
    return (<FieldSetContext.Provider value={{
      values: this.state.values,
      onChange: (key,value)=> this.setState({values:{...this.state.values,[key]:value}},this.validateFields.bind(this))
    }}>
      <Grid {...this.props.gridProps} grow columns={2}>{this.state.fields.map(field=>Displays.renderField(field,FieldSetContext,this.props.options,this.state.fields,this.context.directus))}</Grid>
    </FieldSetContext.Provider>)
  }
}

FieldSet.defaultProps = {  
  collection: "comp_test",
  options: {},
  gridProps: {},
  values:{},
  onChange: ()=>{}
}  
