import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { DirectusContext } from "./Provider/Directus"
import Auth from './Screens/Auth'
import { LoadingOverlay } from '@mantine/core';
import Cases from "./Screens/Cases";
import Dashboard from "./Screens/Dashboard";
import Jobs from "./Screens/Jobs";
import { SettingsContext,defaultState } from "./Contexts/DirectusSettings";
import i18n from "./i18n";
import DefaultShell from "./Components/Shell";
import AddCase from "./Screens/Cases/Add";
import Accounts from "./Screens/Accounts";
import Reviews from "./Screens/Reviews";

export default class App extends React.Component {

  state={
    loggedin:false,
    loading: true,
    settings: defaultState
  } 

  static contextType = DirectusContext

  async componentDidMount() {
    document.title = "CRM-GMB"
    await this.checkAuth()
  } 
  async checkAuth() {
    try{
      const me = await this.getMe()
      await this.getSettings(me)
    }catch(e){
      await this.context.directus.auth
      .refresh()
      .then(this.getSettings)
      .catch(() => this.setState({ loading: false,loggedin:false }));
    }
  }
  async getMe(){
    return await this.context.directus.users.me.read();
  }
  async getSettings(me){
    if(!me) me = await this.getMe()
    const { data: settings} = await this.context.directus.items("directus_settings").readByQuery()
    for(let entry of settings.translation_strings){
      for(let trans of Object.keys(entry.translations)){
        i18n.addResource(trans, 'translation', entry.key, entry.translations[trans]); 
      }
    }
    i18n.changeLanguage(me.language)
    this.setState({ loggedin:true ,settings,me,loading:false})
  }
  render() {
    if(this.state.loading){
      return (<LoadingOverlay visible={true} />)
    }
    if(!this.state.loggedin){
      return (<Auth onLogin={this.checkAuth.bind(this)} />)
    }
    return (
      <SettingsContext.Provider value={{settings:this.state.settings,me:this.state.me}} >
        <DefaultShell>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/cases/add" element={<AddCase />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/accounts" element={<Accounts />} />
          </Routes>
        </DefaultShell>
      </SettingsContext.Provider>
    );
  }
}