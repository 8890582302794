import * as React from 'react';
import { Card, Group, Paper, SimpleGrid, Text, ActionIcon } from '@mantine/core';

export default class PanelCard extends React.Component {
  render () {
    return (
      <Card withBorder shadow='sm' radius='md' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', maxHeight: '100%' }}>
        {(this.props.withHeader && this.props.name) && <Card.Section withBorder inheritPadding py='xs'>
          <Group noWrap>
            {this.props.icon && <ActionIcon>
              <span className='material-icons' style={{ color: this.props.color, fontSize: 20 }}>{this.props.icon}</span>
            </ActionIcon>}
            <Text color='primary' sx={{ whiteSpace: 'nowrap' }} weight='bold'>{this.props.name}</Text>
          </Group>
        </Card.Section>}
        {this.props.children}
      </Card>
    );
  }
}

PanelCard.defaultProps = {
  color: null,
  icon: 'timeline',
  label: '',
  name: '',
  note: null,
  withHeader: true
};
