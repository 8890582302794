import * as React from "react";
import { createStyles,Card,TextInput, Text,Skeleton, UnstyledButton,Group,Center, Table,Pagination, ActionIcon } from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons';
import { DirectusContext } from '../../Provider/Directus';



const defaultLabelHandler = (entry)=>{
  return <Text>{entry.label}</Text>
}
export function byIndex(obj,is, value) {
  if (typeof is === 'string')
      return byIndex(obj,is.split('.'), value);
  else if (is.length===1 && value!==undefined)
      return obj[is[0]] = value;
  else if (is.length===0)
      return obj;
  else
      return byIndex(obj[is[0]],is.slice(1), value);
}

function Th({ children, reversed, sorted, onSort, style }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th} style={{style,whiteSpace: 'nowrap'}}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart" noWrap>
          {children}
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

export default class DirectusTable extends React.Component {
  static contextType = DirectusContext
  state={
    search:"",
    searchActive: false,
    sort:"",
    reversed:false,
    loading:true,
    page:1,
    maxPage:1,
    data: []
  }
  async componentDidMount(){
    await this.update()
  }
  async update(){
    this.setState({ loading: true })
    const {data,meta} = await this.context.directus.items(this.props.collection).readByQuery({
      search: this.state.search,
      filter: this.props.filter,
      limit: this.props.limit,
      fields: [...this.props.fields.map(el=>el.key),...this.props.extraFields],
      page: this.state.page,
      meta: "filter_count",
      sort: this.state.sort?(this.state.reversed?"-"+this.state.sort:this.state.sort):(this.props.sort?this.props.sort:undefined)
    });
    this.setState({ loading: false,data,maxPage:meta.filter_count / this.props.limit })
  }
  onSort(key){
    if(this.state.sort === key){
      this.setState({reversed:!this.state.reversed},this.update)
    }else{
      this.setState({reversed:false,sort:key},this.update)
    }
  }
  onSearch(e){
    this.setState({search:e.currentTarget.value},this.update)
  }
  setPage(page){
    this.setState({page},this.update)
  }

  edit(field,index,value){
    /*eslint no-unused-expressions: 0*/
    this.state.data[index][field]=value,
    this.setState({data:this.state.data})
    return value
  }

  render() {
    return (<>
        <div>
          <Group position="apart">
            {this.props.search && <div>

              {!this.state.searchActive && <ActionIcon
                style={{marginBottom: 16}}
                onClick={()=>this.setState({searchActive:true})}
                size="lg" 
                variant="default"
              ><IconSearch size={18} /></ActionIcon>}
              {this.state.searchActive && <TextInput
                placeholder="Suche"
                mb="md"
                icon={<IconSearch size={14} stroke={1.5} />}
                value={this.state.search}
                onChange={this.onSearch.bind(this)}
              />}
            </div>}
            {this.props.rightContainer  && <div >
              {this.props.rightContainer}
            </div>}
          </Group>
        </div>
        <Card withBorder withShadow style={{padding:0}}>
          <Table {...{highlightOnHover:this.props.highlightOnHover,
                striped:this.props.striped,
                horizontalSpacing:this.props.horizontalSpacing,
                verticalSpacing:this.props.verticalSpacing,
                fontSize:this.props.fontSize}}>
            <thead>
              <tr>
                {this.props.fields.map((head,index)=>(
                  <React.Fragment key={head.key+"_row_head"+index}>
                    {head.sort &&<Th key={head.key+"_row_head_sub"+index} reversed={this.state.reversed} style={head.headStyle} sorted={head.key === this.state.sort} onSort={this.onSort.bind(this,head.key)}>
                      <Text  transform="uppercase" weight={700} size="xs" >{head.renderLabel?head.renderLabel(head): defaultLabelHandler(head)}</Text>
                    </Th>}
                    {!head.sort &&<th key={head.key+"_row_head_sub2"+index} style={head.headStyle}>{head.renderLabel?head.renderLabel(head): defaultLabelHandler(head)}</th>}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.loading && Array(this.props.limit).fill(1).map((b,c)=>(<tr key={c}>
                {Array(this.props.fields.length).fill(1).map((a,i)=>(<th key={i+"_rowskeleton"} style={{textAlign: 'center',}}>
                  <Skeleton width="99%" mb={0} mt={10} height={this.props.skeletonHeight} animated="true" style={{marginLeft:"auto",marginRight:"auto"}}/>
                </th>))}
              </tr>))}
              {!this.state.loading && <React.Fragment >
                {this.state.data.map((entry,ind,list)=>(
                  <tr key={ind+"_rowentry"} onClick={this.props.onClick?this.props.onClick:undefined} style={{cursor:this.props.onClick?"pointer":undefined}}>
                    {this.props.fields.map((field,index2)=>(
                      <React.Fragment key={field.key+"_"+ind+"_"+index2}>
                        {!field.render && <td style={field.style}><Text>{byIndex(entry,field.key)}</Text></td>}
                        {field.render && <td style={field.style}>{field.render(entry, field, ind, this.edit.bind(this))}</td>}
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </React.Fragment>}
            </tbody>
          </Table>
        </Card>
        <div style={{marginTop:10}}>
          {this.props.pagination && <Pagination page={this.state.page} onChange={this.setPage.bind(this)}  total={Math.floor(this.state.maxPage)} withEdges />}
        </div>
      </>
    );
  }
}

DirectusTable.defaultProps = {  
  search: true,
  pagination: true,
  limit: 15,
  striped: true,
  horizontalSpacing:"xs",
  verticalSpacing:"xs",
  fontSize:"xs",
  filter: undefined,
  highlightOnHover: true,
  collection: "tasks",
  skeletonHeight:30,
  onClick: entry=>{},
  extraFields:[],
  fields: [
    {
      label: "ID",
      key: "id",
    }
  ]
}  

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));