import * as React from "react";
import { Center, Text,LoadingOverlay,Title, Card } from '@mantine/core';
import { DirectusContext } from "../../../../Provider/Directus";
import { orderBy,isNil } from 'lodash';
import { addWeeks } from 'date-fns';
import moment from 'moment';
import Chart from "react-apexcharts";
import { abbreviateNumber, adjustDate } from '@directus/shared/utils';
import { cssVar } from '@directus/shared/utils/browser';
import { getGroups } from "./utils";
import PanelCard from "./PanelCard";


const defaultOptions = {
  showHeader: false,
  precision: 'hour',
  color: cssVar('--primary'),
  range: '1 week',
  fillType: 'gradient',
  curveType: 'smooth',
  decimals: 0,
  min: undefined,
  max: undefined,
  showXAxis: true,
  showYAxis: true,
}
export default class TimeSeries extends React.Component {
  
  static contextType = DirectusContext
  state={loading:true,data:[]}

  async componentDidMount() {
    let query = this.query(({...defaultOptions,...this.props.options}))
    const {data} = await this.context.directus.items(query.collection).readByQuery(query.query)
    let metrics = {value:0}
    metrics.value = orderBy(
      data.map((metric) => ({
        x: new Date(this.toISO(metric)).getTime() - (true ? new Date().getTimezoneOffset() * 60 * 1000 : 0),
        y: Number(Number(metric[({...defaultOptions,...this.props.options}).function][({...defaultOptions,...this.props.options}).valueField]).toFixed(({...defaultOptions,...this.props.options}).decimals ?? 0)),
      })),
      'x'
    );
    this.setState({data,metrics,loading:false})
  }
  

	toISO(metric) {
		const year = metric[`${({...defaultOptions,...this.props.options}).dateField}_year`];
		const month = padZero(metric[`${({...defaultOptions,...this.props.options}).dateField}_month`] ?? 1);
		const week = metric[`${({...defaultOptions,...this.props.options}).dateField}_week`];
		const day = week ? padZero(getFirstDayOfNWeeksForYear(week, year)) : padZero(metric[`${({...defaultOptions,...this.props.options}).dateField}_day`] ?? 1);
		const hour = padZero(metric[`${({...defaultOptions,...this.props.options}).dateField}_hour`] ?? 0);
		const minute = padZero(metric[`${({...defaultOptions,...this.props.options}).dateField}_minute`] ?? 0);
		const second = padZero(metric[`${({...defaultOptions,...this.props.options}).dateField}_second`] ?? 0);
		return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
		function padZero(value) {
			return String(value).padStart(2, '0');
		}
		function getFirstDayOfNWeeksForYear(numberOfWeeks, year) {
			return addWeeks(new Date(year, 0, 1), numberOfWeeks).getDate();
		}
	}
  
  query(options) {
    
    if (!options?.function || !options.valueField || !options.dateField) {
			return;
		}
		return {
			collection: options.collection,
			query: {
				groupBy: getGroups(options.precision, options.dateField),
				aggregate: {
					[options.function]: [options.valueField],
				},
				filter: {
					_and: [
						{
							[options.dateField]: {
								_gte: `$NOW(-${options.range || '1 week'})`,
							},
						},
						{
							[options.dateField]: {
								_lte: `$NOW`,
							},
						},
						options.filter || {},
					],
				},
				limit: -1,
			},
		};
	}
  valueLabel () {
    return `${({...defaultOptions,...this.props.options}).valueField} (${({...defaultOptions,...this.props.options}).function})`;
  };
  
  yAxisRange() {
    let min = isNil(({...defaultOptions,...this.props.options}).min) ? undefined : Number(({...defaultOptions,...this.props.options}).min);
    let max = isNil(({...defaultOptions,...this.props.options}).max) ? undefined : Number(({...defaultOptions,...this.props.options}).max);

    if (max !== undefined && !min) {
      min = 0;
    }
    if (max !== undefined && min !== undefined && max < min) {
      max = min;
      min = Number(({...defaultOptions,...this.props.options}).max);
    }
    return { max, min };
  };

  render() {
    if(this.state.loading) return <LoadingOverlay visible overlayBlur={2} />
    
    return (
      <PanelCard withHeader {...this.props}  >
        <Card.Section>
          <Chart 
            type={({...defaultOptions,...this.props.options}).fillType === 'disabled' ? 'line' : 'area'}
            series={[
              {
                name: ({...defaultOptions,...this.props.options}).collection,
                data: this.state.metrics.value,
              },
            ]}
            height={25*this.props.height-(this.props.name?50:0)}
            options={{

              colors: [({...defaultOptions,...this.props.options}).color ? ({...defaultOptions,...this.props.options}).color : "blue"],
              chart: {
                type: ({...defaultOptions,...this.props.options}).fillType === 'disabled' ? 'line' : 'area',
                height: '100%',
                toolbar: {
                  show: false,
                },
                selection: {
                  enabled: false,
                },
                zoom: {
                  enabled: false,
                },
                // fontFamily: 'var(--family-sans-serif)',
                // foreColor: 'var(--foreground-subdued)',
                animations: {
                  enabled: false,
                },

                sparkline: {
                  enabled: !(this.props.options.showYAxis===undefined || this.props.options.showXAxis===undefined)
                },
              },
              series: [
                {
                  name: ({...defaultOptions,...this.props.options}).collection,
                  data: this.state.metrics.value,
                },
              ],
              stroke: {
                curve: ({...defaultOptions,...this.props.options}).curveType,
                width: 2,
                lineCap: 'round',
              },
              markers: {
                hover: {
                  size: undefined,
                  sizeOffset: 4,
                },
              },
              fill: {
                type: ({...defaultOptions,...this.props.options}).fillType === 'disabled' ? 'solid' : ({...defaultOptions,...this.props.options}).fillType,
                gradient: {
                  colorStops: [
                    [
                      {
                        offset: 0,
                        color: ({...defaultOptions,...this.props.options}).color ? ({...defaultOptions,...this.props.options}).color : cssVar('--primary'),
                        opacity: 0.25,
                      },
                      {
                        offset: 100,
                        color: ({...defaultOptions,...this.props.options}).color ? ({...defaultOptions,...this.props.options}).color : cssVar('--primary'),
                        opacity: 0,
                      },
                    ],
                  ],
                },
              },
              grid: {
                borderColor: '#495057',
                padding: {
                  top: ({...defaultOptions,...this.props.options}).showHeader ? -25 : 20,
                  bottom: 0,
                  left: 8,
                },
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                marker: {
                  show: false,
                },
                x: {
                  show: true,
                  formatter(date) {
                    return moment(date).format("DD.MM.YYYY");
                  },
                },
                y: {
                  title: {
                    formatter: () => this.valueLabel() + ': ',
                  },
                  formatter(value) {
                    return value;
                  },
                },
              },
              xaxis: {
                type: 'datetime',
                tooltip: {
                  enabled: false,
                },
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                range: (new Date).getTime() - adjustDate((new Date), `-${({...defaultOptions,...this.props.options}).range}`)?.getTime(),
                max: (new Date).getTime(),
                labels: {
                  show: ({...defaultOptions,...this.props.options}).showXAxis ?? true,
                  offsetY: -4,
                  style: {
                    // fontFamily: 'var(--family-sans-serif)',
                    // foreColor: 'var(--foreground-subdued)',
                    fontWeight: 600,
                    fontSize: '10px',
                  },
                  datetimeUTC: false,
                },
                crosshairs: {
                  stroke: {
                    // color: 'var(--border-normal)',
                  },
                },
              },
              yaxis: {
                show: ({...defaultOptions,...this.props.options}).showYAxis ?? true,
                forceNiceScale: true,
                min: isNil(({...defaultOptions,...this.props.options}).min) ? undefined : Number(({...defaultOptions,...this.props.options}).min),
                max: isNil(({...defaultOptions,...this.props.options}).max) ? undefined : Number(({...defaultOptions,...this.props.options}).max),
                tickAmount: ({...defaultOptions,...this.props.options}).height - 4,
                labels: {
                  offsetY: 1,
                  offsetX: -4,
                  formatter: (value) => {
                    return value > 10000
                      ? abbreviateNumber(value, 1)
                      : value;
                  },
                  yaxis: {
                    show: ({...defaultOptions,...this.props.options}).showYAxis ?? true,
                    forceNiceScale: true,
                    min: this.yAxisRange().min,
                    max: this.yAxisRange().max,
                    tickAmount: ({...defaultOptions,...this.props.options}).height - 4,
                    labels: {
                      offsetY: 1,
                      offsetX: -4,
                      formatter: (value) => {
                        return value > 10000
                          ? abbreviateNumber(value, 1)
                          : value
                      },
                      style: {
                        // fontFamily: 'var(--family-sans-serif)',
                        // foreColor: 'var(--foreground-subdued)',
                        fontWeight: 600,
                        fontSize: '10px',
                      },
                    },
                  },
                },
              },
            }} />
        </Card.Section>
      </PanelCard>
    );
  }
}

TimeSeries.defaultProps = {
  "id": "bb6d1638-eb9c-47a4-ae92-f049366b02be",
  "dashboard": "7adad88d-f981-4213-a9d4-a69f405c94e5",
  "name": "Fälle",
  "icon": "forum",
  "color": null,
  "show_header": true,
  "note": null,
  "type": "metric",
  "position_x": 29,
  "position_y": 1,
  "width": 16,
  "height": 6,
  "options": {
		showHeader: false,
		data: () => [],
		precision: 'hour',
		color: cssVar('--primary'),
		range: '1 week',
		fillType: 'gradient',
		curveType: 'smooth',
		decimals: 0,
		min: undefined,
		max: undefined,
		showXAxis: true,
		showYAxis: true,
  },
}