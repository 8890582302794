import i18n from './';

export function translate (obj) {
  if (typeof obj === 'string') {
    return obj.replaceAll(/(\$t:[a-zA-Z0-9.-]*)/gm, (match) => translateString(match));
  } else {
    const newObj = { ...obj };

    Object.entries(newObj).forEach(([key, val]) => {
      if (val && typeof val === 'object') (newObj)[key] = translate(val);
      if (val && typeof val === 'string') (newObj)[key] = translateString(val);
    });

    return newObj;
  }
}

export function translateString (literal) {
  let translated = literal;
  if (typeof literal === 'string' && literal.startsWith('$t:')) translated = i18n.t(literal.replace('$t:', ''));

  return translated;
}
