import * as React from "react";
import { AppShell, } from '@mantine/core';
import DefaultNavbar from "./Navbar"

export default class DefaultShell extends React.Component {
  render() {
    return (
      <AppShell
        padding="md"
        fixed
        navbar={<DefaultNavbar />}
        // header={<DefaultHeader />}
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}
      >
        {this.props.children}
      </AppShell>
    );
  }
}