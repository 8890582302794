import * as React from 'react';
import { Container, Title, Text, Button,ActionIcon, Drawer ,Menu } from '@mantine/core';
import moment from 'moment';
import DefaultShell from '../../Components/Shell';
import DirectusDashboard from '../../Components/Directus/Dashboard';
import { DirectusContext } from "../../Provider/Directus";
import DirectusTable from '../../Components/Directus/Table';
import FieldSet from '../../Components/Directus/FieldSet';
import { IconPlayerPlay, IconPlus,IconStars } from '@tabler/icons';

export default class Accounts extends React.Component {
  static contextType = DirectusContext
  state={
    loading:true,
    createReview: false,
    currentToken: "",
    newReviewData:{}
  }
  async componentDidMount() {
    const staticToken= await this.context.directus.utils.random.string();
    await this.context.directus.items("directus_users").updateOne((await this.context.directus.users.me.read()).id,{
      token: staticToken
    })
    this.setState({ loading:false, currentToken: staticToken});
    
  }
  onChangeAuth(id,authority){
    return this.context.directus.items("accounts").updateOne(id,{authority})
  }
  
  onChangeReview(newReviewData){
    this.setState({ newReviewData })
  }
  async onSaveReview(){
    this.setState({createReview: false,newReviewData:{},loadingReview:true});
    try{
      await this.context.directus.items("manual_review").createOne(this.state.newReviewData)
      this.setState({createReview: false,newReviewData:{},loadingReview:false});
    }catch(e){
      this.setState({loadingReview:false});
    }
  }

  render () {
    return (
      <>
        
        <Drawer
          opened={!!this.state.createReview}
          onClose={() => this.setState({createReview:false})}
          title="Review hinzufügen"
          padding="xl"
          size="xl"
        >
          <FieldSet collection="manual_review" key={this.state.createReview} values={{account:this.state.createReview,stars:1}} onChange={(value,valid)=>this.onChangeReview(value)} />
          <Button disabled={this.state.loadingReview} onClick={this.onSaveReview.bind(this)} fullWidth mt="md">Hinzufügen</Button>
        </Drawer>
        <Container fluid >
          <div style={{marginTop:30}}>
            <Title>Google-Accounts</Title>
            <Text>Hier kannst du die Google Accounts verwalten und den Browser starten</Text>
          </div>
          <div style={{marginTop:20}}>
            <DirectusTable
              collection="accounts"
              horizontalSpacing="sm"
              verticalSpacing="sm"
              search={true}
              sort={"-authority,-date_updated"}
              filter={{"_and":[{"_and":[{"status":{"_eq":"published"}},{"is_local_guide":{"_eq":true}},{"has_reviews_hidden":{"_eq":true}},{"banned":{"_eq":false}},{"disabled_until":{"_lt":"$NOW"}},{"used_email":{"_nempty":true}}]},{"status":{"_neq":"archived"}}]}}
              // onClick={this.pro}
              extraFields={["person.givenname","person.surname"]}
              fields={[
                {
                  label: "Status",
                  key: "status",
                },
                {
                  label: "Name",
                  key: "start_at",
                  style:{whiteSpace: "nowrap"},
                  render: val=><Text>{val.person.givenname} {val.person.surname}</Text>,
                  sort:true
                },
                {
                  label: "GMAIL",
                  key: "gmail",
                  headStyle:{width: "100%"},
                },
                {
                  label: "Authorität",
                  key: "authority",
                  render: (val,field,index,edit)=>(
                    <Button.Group >
                      <Button onClick={()=>{this.onChangeAuth(val.id,val.authority-1).then(r=>edit(field.key,index,val.authority-1))}} compact variant="outline" style={{opacity:0.5}} color="red">-</Button>
                      <Button compact variant="default" color={val.authority<20?"red":undefined}>{val.authority}</Button>
                      <Button onClick={()=>{this.onChangeAuth(val.id,val.authority+1).then(r=>edit(field.key,index,val.authority+1))}} compact variant="outline" style={{opacity:0.5}} color="green">+</Button>
                    </Button.Group>
                  )
                },
                {
                  label: "Erstellt am",
                  key: "date_created",
                  render: val=><Text>{moment(val.date_created).format('DD.MM.YYYY')}</Text>,
                  sort:true
                },
                {
                  label: "Erstellt von",
                  key: "user_created.first_name",
                  render: val=><Text>{val?.user_created?.first_name} {val?.user_created?.last_name}</Text>,
                  sort:true
                },
                {
                  label: "Erstellen",
                  key: "id",
                  compact: true,
                  render: val=><Menu shadow="md" width={200}>
                    <Menu.Target>
                      <ActionIcon variant="filled">
                        <IconPlus size={18} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>Hinzufügen</Menu.Label>
                      <Menu.Item onClick={()=>this.setState({createReview:val.id})} icon={<IconStars size={14} />}>Review</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>,
                },
                {
                  label: "Starten",
                  key: "id",
                  compact: true,
                  render: val=><Text>
                  <ActionIcon component="a" href={"stealthbrowser://start?auth="+this.state.currentToken+"&account="+val.id} color="green" variant="filled">
                    <IconPlayerPlay size={18} />
                  </ActionIcon>
                  </Text>,
                },
              ]}
            />
          </div>
        </Container>
      </>
    );
  }
}
