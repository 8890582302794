import * as React from 'react';
import { Container, Title, Text, Badge,Progress, Modal } from '@mantine/core';
import moment from 'moment';
import DefaultShell from '../../Components/Shell';
import DirectusDashboard from '../../Components/Directus/Dashboard';
import { DirectusContext } from "../../Provider/Directus";
import DirectusTable from '../../Components/Directus/Table';
import FieldSet from '../../Components/Directus/FieldSet';

export default class Jobs extends React.Component {
  static contextType = DirectusContext
  state={loading:true,me:{
    first_name:"Lorem",
    last_name:"Ipsumcontent"
  }}
  async componentDidMount() {
    this.setState({ loading:false, me:await this.context.directus.users.me.read() });
  }
  render () {
    return (
      <>
        <Modal >
          <FieldSet />
        </Modal>
        <Container fluid >
          <div style={{marginTop:30}}>
            <Title>Remote-Jobs</Title>
            <Text>Hier kannst du dir die Aktiven Aufgaben verwalten welche von unserem System automatisiert verarbeitet werden.</Text>
          </div>
          <DirectusDashboard dashboard="3c126378-e4f0-4528-b6fa-64e994a91ab0" />
          <div style={{marginTop:20}}>
            <DirectusTable
              collection="tasks"
              horizontalSpacing="sm"
              verticalSpacing="sm"
              search={false}
              sort={"-date_updated"}
              onClick={this.pro}
              extraFields={["user_created.last_name"]}
              fields={[
                {
                  label: "Status",
                  key: "status",
                  sort:true,
                  render: val=>{
                    if(val.status){
                      return <>
                        {(()=>{
                            if(val?.status == "finished") return <><Badge color="green" radius="sm" variant="outline">Erledigt</Badge></>
                            if(val?.status == "failed") return <><Badge color="red" radius="sm" variant="filled">ERROR</Badge></>
                            if(val?.status == "progress") return <><Badge color="yellow" radius="sm" variant="outline">Running</Badge></>
                            // if(val?.location?.rating < 3) return <><Badge  sx={{ paddingLeft: 4 }} leftSection={<IconStar size="10" />} color="red" radius="sm" variant="filled">{val?.location?.rating}</Badge></>
                            return <><Badge radius="sm" variant="outline">{val?.status}</Badge></>
                        })()}
                      </>
                    }else{return ""}
                  }
                },
                {
                  label: "Aufgabe",
                  key: "task",
                  sort:true
                },
                {
                  label: "Fortschritt",
                  key: "progress",
                  sort:true,
                  render: val=><Progress value={val.progress} />
                },
                {
                  label: "Fehlercode",
                  key: "error_code",
                  sort:true
                },
                {
                  label: "Starten am",
                  key: "start_at",
                  render: val=><Text>{moment(val.start_at).format('DD.MM.YYYY')}</Text>,
                  sort:true
                },
                {
                  label: "Geändert am",
                  key: "date_updated",
                  render: val=><Text>{val.date_updated?moment(val.date_updated).format('DD.MM.YYYY'):""}</Text>,
                  sort:true

                },
                {
                  label: "Erstellt am",
                  key: "date_created",
                  render: val=><Text>{moment(val.date_created).format('DD.MM.YYYY')}</Text>,
                  sort:true
                },
                {
                  label: "Erstellt von",
                  key: "user_created.first_name",
                  render: val=><Text>{val?.user_created?.first_name} {val?.user_created?.last_name}</Text>,
                  sort:true
                },
              ]}
            />
          </div>
        </Container>
      </>
    );
  }
}
