import { Accordion, Grid } from '@mantine/core';
import Displays from '.';
import DefaultInput from './DefaultInput';

export default class GroupDetailDisplay extends DefaultInput {
  renderInput (props) {
    return (
      <Accordion variant='contained'>
        <Accordion.Item key={props.field.field} value={props.field.field}>
          <Accordion.Control icon={props.field.meta.options?.headerIcon ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.headerIcon}</span> : undefined}>{this.renderLabel(props.field.field, props.field.meta?.translations)}</Accordion.Control>
          <Accordion.Panel>
            <Grid columns={2}>
              {props.field.items.filter(el => DefaultInput.calculateProps(el, this.context.values).meta.hidden !== true).map(field => Displays.renderField(field, props.context, props.options, props.fields))}
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  }
}
