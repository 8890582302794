import { NumberInput, TextInput } from '@mantine/core';
import DefaultInput from './DefaultInput';
const typeDefinitions = {
  numbers: ['float', 'integer', 'decimal', 'bigInteger']

};

export default class InputDisplay extends DefaultInput {
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),
      placeholder: props.field.meta.options?.placeholder,
      description: props.field.meta.note,
      required: props.field.meta.required,
      disabled: props.field.meta.readonly,
      type: props.field.meta?.options?.masked ? 'password' : undefined,
      icon: props.field.meta.options?.iconLeft ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.iconLeft}</span> : undefined,
      rightSection: props.field.meta.options?.iconRight ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.iconRight}</span> : undefined
    };
    if (typeDefinitions.numbers.includes(props.field.type)) {
      return (
        <NumberInput
          {...inputProps}
          value={this.context.values[props.field.field]}
          onChange={(value) => this.context.onChange(props.field.field, value)}
          defaultValue={props.field.schema.default_value}
          {...this.props.field.options}
        />
      );
    }
    return (
      <TextInput
        value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
        onChange={(e) => this.context.onChange(props.field.field, e.currentTarget.value)}
        {...inputProps}
        {...this.props.field.options}
      />
    );
  }
}
