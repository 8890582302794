import {Component, forwardRef} from 'react';
import { Card, Autocomplete, TextInput, Text,Loader, Button, Group, LoadingOverlay, Stepper } from '@mantine/core';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl
} from 'react-leaflet'
import { IconLocation,IconSearch } from '@tabler/icons';
import _ from 'lodash';
import L from 'leaflet';
import * as Nominatim from "nominatim-browser";
import { DirectusContext } from '../../../Provider/Directus';

const iconLocation = new L.Icon({
    iconUrl: require('../../../Assets/iconMarker.png'),
    iconRetinaUrl: require('../../../Assets/iconMarker.png'),
    iconSize: new L.Point(45, 45),
});

export default class SelectPlace extends Component {

  static contextType = DirectusContext
  state={
    mapCenter: [51.1642292, 10.4541194],
    locationSearch: "", 
    locationResults: [],
    locationLoading:false,
    searchResult: [],
    searchLoading:false
  }
  onLocationSearch (val){
    this.setState({locationSearch:val})
    if(val.length>3){
      this.debounced()
    }
  }
  
  debounced = _.debounce(this.locationAutocomplete.bind(this),1000)
  async locationAutocomplete (){
    this.setState({locationLoading:true})
    Nominatim.geocode({
      q: this.state.locationSearch,
      country:"DE"
    }).then(result=>{
      this.setState({locationLoading:false, locationResults:result.map(el=>el.display_name
      )})
    }).catch(()=>{
      this.setState({locationLoading:false, locationResults:[]})
    })
  }

  async onSearch(){
    this.setState({searchLoading:true,searchResult:[]})
    try{
      const results = await this.context.directus.transport.get(
        "scaleserp/valueserp",
        {params:{
          search_type: "places",
          q: this.state.keyword,
          location: this.state.locationSearch,
          google_domain: "google.de",
          gl: "de",
          hl: "de"
        }}
      )
      this.setState({searchLoading:false,searchResult:results.raw.places_results})
    }catch(e){
      console.log(e)
      this.setState({searchLoading:false,searchResult:[]})
      
    }
  }

  render () {
    return (
      <div style={{
        position: 'relative',
        width: "calc(100% + 32px)",
        height: "calc(100% + 32px)",
        top:0,
        margin: -16
      }}>

          <Card withBorder shadow="xl" 
            sx={(theme) => ({
              position: 'absolute',
              padding: 16,
              top:16,left:16,
              maxWidth: 300,
              width:"100%",
              bottom:16,
              overflow: 'auto',
              zIndex:9000,
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] ,
            })}
          >

            <LoadingOverlay visible={this.state.searchLoading} overlayBlur={2} />
            <Card.Section  inheritPadding py="xs">
              <Text weight={500} style={{marginBottom:10}}>Unternehmenssuche</Text>
              <Autocomplete
                icon={<IconLocation size={16} />}
                label="Standort"
                value={this.state.locationSearch}
                onChange={this.onLocationSearch.bind(this)}
                data={this.state.locationResults}
                rightSection={this.state.locationLoading?<Loader size="xs" />:undefined}
              />
              <TextInput
                icon={<IconSearch size={16} />}
                label="Keyword"
                value={this.state.keyword}
                onChange={e=>this.setState({keyword:e.target.value})}
              />
              <Button onClick={this.onSearch.bind(this)} style={{marginTop:10}} size="xs" fullWidth>Suchen</Button>
            </Card.Section>
            <Card.Section withBorder inheritPadding py="xs">
              {this.state.searchResult.sort((a,b)=>(a.rating % 1) - (b.rating % 1)).map(el=>(
                <Card 
                component="a"
                href="#"
                onClick={()=>this.props.setState({step:2,place:el})}
                withBorder key={el.data_cid} mb={10} >
                  <Card.Section  p={5}>
                    <Text size="sm" weight={700} >
                      {el.title}
                    </Text>
                  </Card.Section>
                  <Card.Section withBorder style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding:10
                  }}>
                    <div>
                      <Text size="xs" color="dimmed">
                        Rating
                      </Text>
                      <Text weight={500} size="sm">
                        {el.rating}/5
                      </Text>
                    </div>
                    <div>
                      <Text size="xs" color="dimmed">
                        Bewertungen
                      </Text>
                      <Text weight={500} size="sm">
                        {el.reviews}
                      </Text>
                    </div>
                    <div>
                      <Text size="xs" color="dimmed">
                        Kategorie
                      </Text>
                      <Text weight={500} size="sm">
                        {el.category}
                      </Text>
                    </div>
                  </Card.Section>
                </Card>
              ))}
            </Card.Section>
          </Card>
        <div style={{
          position: 'absolute',
          top:0,bottom:0,left:0,right:0,width: '100%',height:"100%",overflow: 'hidden'
        }}>
          <MapContainer style={{height:"100%"}} center={this.state.mapCenter} zoom={7} zoomControl={false} scrollWheelZoom={true}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomControl position={'bottomright'} />

            {this.state.searchResult.map(el=>(
              <Marker icon={ iconLocation } position={el.gps_coordinates ? [el.gps_coordinates.latitude, el.gps_coordinates.longitude]:undefined}>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    );
  }
}
