import { Accordion } from '@mantine/core';
import Displays from '.';
import DefaultInput from './DefaultInput';

export default class AccordionDisplay extends DefaultInput {
  renderInput (props) {
    return (
      <Accordion variant='separated'>
        {props.field.items.filter(el => DefaultInput.calculateProps(el, this.context.values).meta.hidden !== true).map(field => (
          <Accordion.Item key={field.field} value={field.field}>
            <Accordion.Control>{this.renderLabel(field.field, props.field.meta?.translations)}</Accordion.Control>
            <Accordion.Panel>
              {Displays.renderField(field, props.context, props.options, props.fields)}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  }
}
