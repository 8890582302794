import { Input, NumberInput, Switch, TextInput } from '@mantine/core';
import DefaultInput from './DefaultInput';

export default class SwitchDisplay extends DefaultInput {
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),

      description: props.field.meta.note,
      required: props.field.meta.required

    };
    return (
      <Input.Wrapper
        {...inputProps}
      >
        <Switch
          value={this.context.values[props.field.field]}
          onChange={({ target }) => this.context.onChange(props.field.field, target.checked)}
          defaultValue={props.field.schema.default_value}
          {...this.props.field.meta.options}
        />
      </Input.Wrapper>
    );
  }
}
