import DefaultInput from './DefaultInput';
import { RichTextEditor } from '@mantine/rte';
import { Input } from '@mantine/core';

export default class EditorDisplay extends DefaultInput {
  async handleImageUpload (file) {
    return file;
    // TODO: provide inline images
    // const formData = new FormData();
    // formData.append('image', file);
    // Dire
    // fetch('https://api.imgbb.com/1/upload?key=api_key', {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then((response) => response.json())
    //   .then((result) => resolve(result.data.url))
    //   .catch(() => reject(new Error('Upload failed')));
  }

  renderInput (props) {
    const inputProps = {
      placeholder: props.field.meta.options?.placeholder,
      disabled: props.field.meta.readonly
    };
    return (
      <Input.Wrapper
        {...{
          label: this.renderLabel(props.field.field, props.field.meta?.translations),
          description: props.field.meta.note,
          required: props.field.meta.required
        }}
      >
        <RichTextEditor
          {...inputProps}
          {...this.props.field.options}
          value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
          onChange={(e) => this.context.onChange(props.field.field, e)}
        />
      </Input.Wrapper>
    );
  }
}
