import * as React from "react";
import { Card, LoadingOverlay } from '@mantine/core';
import GridLayout,{WidthProvider} from "react-grid-layout";
import { DirectusContext } from '../../../Provider/Directus';
import Panels from "./Panels";


const ResponsiveGridLayout = WidthProvider(GridLayout);
const LazyComponent = (root,child)=>{ return root[child] }
export default class DirectusDashboard extends React.Component {
  static contextType = DirectusContext
  state={
    panels: [],
    dashboard: {
      name: "Loading"
    },
    loading: true,
  }
  async componentDidMount(){
    await this.update()
  }
  async update(){
    this.setState({ loading: true })
    const dashboard = await this.context.directus.items("directus_dashboards").readOne(this.props.dashboard);
    const {data:panels} = await this.context.directus.items("directus_panels").readMany(dashboard.panels);
    this.setState({ loading: false,dashboard,panels })
  }

  render() {
    if(this.state.loading) return <Card style={{minHeight:150, marginTop:25}}><LoadingOverlay visible overlayBlur={2} /></Card>
    const maxElement= this.state.panels.reduce(function(prev, current) {
      return (prev.position_x > current.position_x) ? prev : current
    })
    const maxWidth = (maxElement.width + maxElement.position_x)-1
    const layout = this.state.panels.map(el=>({i: el.id, x: el.position_x-1, y: el.position_y, w: el.width, h: el.height, static: true}))
    return (
      <>
        <ResponsiveGridLayout containerPadding={[0,0]} margin={[0,0]} layout={layout} cols={maxWidth} rowHeight={25}  >
          {this.state.panels.sort((val1,val2)=>val1.position_x<val2.position_x).map(panel=>(
            <div key={panel.id}>
                {Panels[panel.type] ? React.createElement(Panels[panel.type],panel) : panel.type+" is not supported"}
            </div>
          ))}
        </ResponsiveGridLayout>
      </>
    );
  }
}

DirectusDashboard.defaultProps = {  
  dashboard: "7adad88d-f981-4213-a9d4-a69f405c94e5"
}  
