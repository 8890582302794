import * as React from 'react';
import { Text, Dialog, Center, Tooltip, UnstyledButton, Badge, Progress } from '@mantine/core';
import { IconActivityHeartbeat } from '@tabler/icons';
import moment from 'moment';
import DirectusTable from '../../Directus/Table';

export default class JobList extends React.Component {
  state={
    open:false,
    loading:true
  }
  
  // async componentDidMount(){
  //   await this.update()
  //   this.setState({ loading: false })
  //   this.interval = setInterval(() => this.update(), 5000);
  // }

  // componentWillUnmount(){
  //   clearInterval(this.interval);
  // }

  render () {
    return (
      <>
        <Tooltip label='Jobs' position='right' transitionDuration={0}>
          <UnstyledButton onClick={()=>this.setState({open:!this.state.open})} sx={theme => ({ width: 50, height: 50, borderRadius: theme.radius.md, display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.white, opacity: 0.85 })}>
            <IconActivityHeartbeat stroke={1.5} />
          </UnstyledButton>
        </Tooltip>
        <Dialog
          opened={this.state.open}
          withCloseButton
          position={{ bottom: 20, left:100 }}
          onClose={() => this.setState({open:false})}
          size="lg"
          radius="sm"
          sx={{padding:0}}
        >
          {this.state.open && <DirectusTable 
            pagination={false} 
            search={false} 
            limit={5}
            collection="tasks"
            horizontalSpacing="xs"
            verticalSpacing="xs"
            sort={"-date_updated"}
            extraFields={["user_created.last_name"]}
            fields={[
              {
                label: "Status",
                key: "status",
                render: val=>{
                  if(val.status){
                    return <>
                      {(()=>{
                          if(val?.status == "finished") return <><Badge color="green" radius="sm" variant="outline">Erledigt</Badge></>
                          if(val?.status == "failed") return <><Badge color="red" radius="sm" variant="filled">ERROR</Badge></>
                          if(val?.status == "progress") return <><Badge color="yellow" radius="sm" variant="outline">Running</Badge></>
                          // if(val?.location?.rating < 3) return <><Badge  sx={{ paddingLeft: 4 }} leftSection={<IconStar size="10" />} color="red" radius="sm" variant="filled">{val?.location?.rating}</Badge></>
                          return <><Badge radius="sm" variant="outline">{val?.status}</Badge></>
                      })()}
                    </>
                  }else{return ""}
                }
              },
              {
                label: "Aufgabe",
                key: "task",
              },
              {
                label: "Fortschritt",
                key: "progress",
                render: val=><Progress value={val.progress} />
              },
            ]}
          />}
        </Dialog>
      </>
    );
  }
}
