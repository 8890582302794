import * as React from 'react';
import { Grid } from '@mantine/core';
import { parseFilter,validatePayload  } from '@directus/shared/utils';
import { FieldSetContext} from '../../../Contexts/DirectusFieldSet'
import i18n from '../../../i18n';

export default class DefaultInput extends React.Component {
  static contextType = FieldSetContext

  static calculateProps (item,values) {
    if(item.items){
      if((item.items.filter(el=>this.calculateProps(el).meta.hidden === false)).length<1){
        return {...item,meta:{...item.meta,hidden:true}}
      }
    }
    if(!item.meta?.conditions) return item;
		const conditions = [...item.meta.conditions].reverse();
		const matchingCondition = conditions.find((condition) => {
      if (!condition.rule || Object.keys(condition.rule).length !== 1) return;
			const rule = parseFilter(condition.rule);
			const errors = validatePayload(rule, values, { requireAll: true });
			return errors.length === 0;
		});
		if (matchingCondition) {
			return {
				...item,
				meta: {
          ...item.meta,
					readonly: matchingCondition.readonly,
					options: matchingCondition.options,
					hidden: matchingCondition.hidden,
					required: matchingCondition.required,
				},
			};
		}
    return item; 
  }

  capitalize (str) {
    return str.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  }

  renderLabel (label, translation) {
    if (!translation) {
      return this.capitalize(label.replace('_', ' '));
    }
    let trans = null
    for(let lang of i18n.languages){
      trans = translation.find(val=>val.language==lang)
      if(trans){
        break
      }
    }
    if(trans){
      return trans.translation
    }
    return this.capitalize(label.replace('_', ' '));
  }

  render(){
    let props = {...this.props,field: DefaultInput.calculateProps(this.props.field,this.context.values)}
    if(props.field.meta.hidden) return null
    return (<Grid.Col key={props.field.field} span={this.props.field.meta.width == "full" ? 2:1}>
      {this.renderInput(props)}
    </Grid.Col>)
  }

}
