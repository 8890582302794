import { Grid } from '@mantine/core';
import React from 'react';
import Displays from '.';
import { FieldSetContext } from '../../../Contexts/DirectusFieldSet';
import DefaultInput from './DefaultInput';

export default class GroupDisplay extends React.Component {

  static contextType = FieldSetContext
  render () {
    const props = { ...this.props, field: DefaultInput.calculateProps(this.props.field,this.context.values) };
    if (props.field.meta.hidden) return null;
    return (<Grid columns={2}>{props.field.items.map(field => Displays.renderField(field, props.context, props.options, props.fields))}</Grid>)
  }
}
