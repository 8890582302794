import {Component, forwardRef} from 'react';
import { Card, Text, Button, Center, Accordion, Group, ActionIcon } from '@mantine/core';
import moment from 'moment';
import { DirectusContext } from '../../../Provider/Directus';

import FieldSet from '../../../Components/Directus/FieldSet';
import { IconPlus } from '@tabler/icons';

export default class AddReview extends Component {

  static contextType = DirectusContext
  state={
    entries: [
      {stars:1,post_after:moment().toDate()},
      {stars:1,post_after:moment().add(1,'days').toDate()},
      {stars:1,post_after:moment().add(2,'days').toDate()}
    ]
  }

  onUpdate(ind,value,valid){
    this.state.entries[ind]= value
    this.setState({entries: this.state.entries})
    this.props.setState({reviews: [...this.state.entries]})
  }

  removeEntry(rmIndex){
    let newEntries = [...this.state.entries]
    newEntries.splice(rmIndex, 1)
    this.setState({entries: newEntries})
  }

  render () {
    return (
      <div>
        <Center style={{ width: "100%", height: "100%" }}>
          <div style={{width:400, maxWidth:"100%", paddingTop:90}}>
            <Card withBorder shadow="xl" style={{}} >
              <Group position="apart">
                <div>
                  <Text size="sm" weight={700} >
                    Bewertungen erstellen
                  </Text>
                  <Text color="dimmed" size="xs">
                    Ansprechpartner für E-Mails und Post
                  </Text>
                </div>
                <ActionIcon color="green" variant="filled" onClick={()=>this.setState({entries:[...this.state.entries,{stars:1,post_after:moment().add(this.state.entries.length,'days').toDate()}]})}>
                  <IconPlus size={18} />
                </ActionIcon>
              </Group>
            </Card>

            <Accordion mt="lg" variant="contained" >
              {
                this.state.entries.map((el,ind)=>(
                <Accordion.Item key={"review"+ind} value={"review"+ind}>
                  <Accordion.Control>Bewertung #{ind+1}</Accordion.Control>
                  <Accordion.Panel>
                    <FieldSet values={el} key={"contact-add"} collection={"reviews"} onChange={(value,valid)=>this.onUpdate(ind,value,valid)}/>
                    <Button color="red" size="xs" onClick={()=>this.removeEntry(ind)} fullWidth mt="md">Löschen</Button>
                  </Accordion.Panel>
                </Accordion.Item>)
              )}
            </Accordion>
            <Card withBorder shadow="xl" mt="lg" >
              <Button fullWidth onClick={()=>this.props.setState({step:4,reviews:this.state.entries},this.props.uploadStart)} >Erstellen</Button>
            </Card>
          </div>
        </Center>
      </div>
    );
  }
}
