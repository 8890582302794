import * as React from 'react';
import { Container, Title, Text, Button,ActionIcon, Drawer ,Menu, Switch } from '@mantine/core';
import moment from 'moment';
import DefaultShell from '../../Components/Shell';
import DirectusDashboard from '../../Components/Directus/Dashboard';
import { DirectusContext } from "../../Provider/Directus";
import DirectusTable from '../../Components/Directus/Table';
import FieldSet from '../../Components/Directus/FieldSet';
import { IconPlayerPlay, IconPlus,IconStars } from '@tabler/icons';

export default class Accounts extends React.Component {
  static contextType = DirectusContext
  state={
    loading:true,
    createReview: false,
    currentToken: "",
    newReviewData:{}
  }
  async componentDidMount() {
    const staticToken= await this.context.directus.utils.random.string();
    await this.context.directus.items("directus_users").updateOne((await this.context.directus.users.me.read()).id,{
      token: staticToken
    })
    this.setState({ loading:false, currentToken: staticToken});
    
  }
  onChangeAuth(id,authority){
    return this.context.directus.items("accounts").updateOne(id,{authority})
  }
  
  onChangeReview(newReviewData){
    this.setState({ newReviewData })
  }
  async onSaveReview(){
    this.setState({createReview: false,newReviewData:{},loadingReview:true});
    try{
      await this.context.directus.items("manual_review").createOne(this.state.newReviewData)
      this.setState({createReview: false,newReviewData:{},loadingReview:false});
    }catch(e){
      this.setState({loadingReview:false});
    }
  }

  render () {
    return (
      <>
        
        <Drawer
          opened={!!this.state.createReview}
          onClose={() => this.setState({createReview:false})}
          title="Review hinzufügen"
          padding="xl"
          size="xl"
        >
          <FieldSet collection="manual_review" key={this.state.createReview} values={{account:this.state.createReview,stars:1}} onChange={(value,valid)=>this.onChangeReview(value)} />
          <Button disabled={this.state.loadingReview} onClick={this.onSaveReview.bind(this)} fullWidth mt="md">Hinzufügen</Button>
        </Drawer>
        <Container fluid >
          <div style={{marginTop:30}}>
            <Title>Manuelle Bewertungen</Title>
            <Text>Übersicht und verwaltung von Manuell erstellten Bewertungen</Text>
          </div>
          <div style={{marginTop:20}}>
            <DirectusTable
              collection="manual_review"
              horizontalSpacing="sm"
              verticalSpacing="sm"
              search={true}
              sort={"-date_updated"}
              // onClick={this.pro}
              extraFields={["account.person.givenname","account.person.surname"]}
              fields={[
                {
                  label: "Account",
                  key: "account.person.givenname",
                  style:{whiteSpace: "nowrap"},
                  render: val=><Text>{val.account.person.givenname} {val.account.person.surname}</Text>,
                  sort:true
                },
                {
                  label: "Keyword",
                  key: "keyword"
                },
                {
                  label: "Unternehmen",
                  key: "company"
                },
                {
                  label: "Sterne",
                  key: "stars"
                },
                {
                  label: "Sichtbar",
                  key: "visible",
                  render:val=><Switch value={val.visible} disabled={true} />
                },
                {
                  label: "Geändert am",
                  key: "date_updated",
                  render: val=><Text>{moment(val.date_created).format('DD.MM.YYYY')}</Text>,
                  sort:true
                },
                {
                  label: "Erstellt am",
                  key: "date_created",
                  render: val=><Text>{moment(val.date_created).format('DD.MM.YYYY')}</Text>,
                  sort:true
                },
                {
                  label: "Erstellt von",
                  key: "user_created.first_name",
                  render: val=><Text>{val?.user_created?.first_name} {val?.user_created?.last_name}</Text>,
                  sort:true
                },
              ]}
            />
          </div>
        </Container>
      </>
    );
  }
}
