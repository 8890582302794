import { Select,Skeleton,Input } from '@mantine/core';
import { SettingsContext } from '../../../Contexts/DirectusSettings';
import { translate } from '../../../i18n/translate';
import DefaultInput from './DefaultInput';
import {render} from 'micromustache';
import tweeze from 'tweezers';

export default class SelectM2ODisplay extends DefaultInput {
  state= {
    loading:true,data:[]
  }
  async componentDidMount() {
    var tokens = tweeze(this.props.field.meta.options.template);
    const {data} = await this.props.directus.items(this.props.field.schema.foreign_key_table).readByQuery({fields:[this.props.field.schema.foreign_key_column,...Object.keys(tokens)],filter:this.props.field.meta.options.filter,limit:1000})
    this.setState({loading:false,data:data.map(el=>({
      value: el[this.props.field.schema.foreign_key_column],
      label: render(this.props.field.meta.options.template,el)
    }))})
  }
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),
      placeholder: props.field.meta.options?.placeholder,
      description: props.field.meta.note,
      required: props.field.meta.required,
      disabled: props.field.meta.readonly,
      clearable: props.field.meta.options?.allowNone,
      //TODO: make element createable
      creatable: false,
      icon: props.field.meta.options?.icon ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.icon}</span> : undefined
    };
    if(this.state.loading){
      return (<Input.Wrapper {...inputProps}>
        <Skeleton width={"99%"} mb={0} mt={10} height={30} animated="true" />
      </Input.Wrapper>)
    }
    return (
      <Select
        searchable={inputProps.creatable}
        data={this.state.data}
        value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
        onChange={(e) => this.context.onChange(props.field.field, e)}
        onCreate={inputProps.creatable? (query)=>this.setState({extraFields:[{ value: query, label: query },...this.state.extraFields]}) :undefined}
        getCreateLabel={(query) => `+ ${query}`}
        {...inputProps}
        {...this.props.field.options}
      />
    );
  }
}
