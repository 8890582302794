import { Input, NumberInput, Slider, TextInput } from '@mantine/core';
import DefaultInput from './DefaultInput';

export default class SliderDisplay extends DefaultInput {
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),

      description: props.field.meta.note,
      required: props.field.meta.required,

      type: props.field.meta?.options?.masked ? 'password' : undefined,
      icon: props.field.meta.options?.iconLeft ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.iconLeft}</span> : undefined,
      rightSection: props.field.meta.options?.iconRight ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.iconRight}</span> : undefined
    };
    return (
      <Input.Wrapper
        {...inputProps}
      >
        <Slider
          precision={4}
          labelAlwaysOn={props.field?.meta?.options?.alwaysShowValue === true}
          disabled={props.field.meta.readonly}
          max={props.field.meta.options?.maxValue}
          min={props.field.meta.options?.minValue}
          step={props.field.meta.options?.stepInterval}
          value={this.context.values[props.field.field]}
          onChange={(value) => this.context.onChange(props.field.field, value)}
          defaultValue={props.field.schema.default_value}
          {...this.props.field.options}
        />
      </Input.Wrapper>
    );
  }
}
