import * as React from "react";
import { DirectusContext } from "../../Provider/Directus";
import {
  AppShell,
  Header,
  Center,
  TextInput,
  PasswordInput,
  Button,
  Text,
  Container,
  Title,
  Anchor,
  Paper,
  Group,
  Checkbox,
} from "@mantine/core";

export default class App extends React.Component {
  static contextType = DirectusContext;

  state = {
    loading: false,
    error: false,
  };

  async onLogin() {
    this.setState({ loading: true, error: false });
    await this.context.directus.auth
      .login({ email: this.state.username, password: this.state.password })
      .then(() => {
        this.props.onLogin();
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false, error: true });
      });
  }

  render() {
    return (
      <AppShell
        padding="md"
        header={
          <Header height={60} p="xs" style={{ textAlign: "center" }}>
            <img
              alt="infinity"
              style={{ height: "100%" }}
              src={require("../../Assets/tcy-quadrat.png")}
            />
          </Header>
        }
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Center style={{ minHeight: "90vh" }}>
          <Container size={420} my={40}>
            <Title
              align="center"
              sx={(theme) => ({
                fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                fontWeight: 900,
              })}
            >
              Willkommen zurück!
            </Title>
            <Text color="dimmed" size="sm" align="center" mt={5}>
              Bitte melde dich zuerst an.
            </Text>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <TextInput
                error={
                  this.state.error
                    ? "Benutzer/Passwort kombination wurde nicht gefunden."
                    : undefined
                }
                onChange={(e) =>
                  this.setState({ username: e.currentTarget.value })
                }
                disabled={this.state.loading}
                label="E-Mail"
                placeholder="you@company.com"
                required
              />
              <PasswordInput
                onChange={(e) =>
                  this.setState({ password: e.currentTarget.value })
                }
                disabled={this.state.loading}
                label="Passwort"
                placeholder="*************"
                required
                mt="md"
              />
              <Group position="apart" mt="md">
                <Checkbox label="Angemeldet bleiben?" />
                <Anchor
                  onClick={(event) => event.preventDefault()}
                  href="#"
                  size="sm"
                >
                </Anchor>
              </Group>
              <Button
                onClick={this.onLogin.bind(this)}
                disabled={!(this.state.password && this.state.username)}
                loading={this.state.loading}
                fullWidth
                mt="xl"
              >
                Sign in
              </Button>
            </Paper>
          </Container>
        </Center>
      </AppShell>
    );
  }
}
