import { Select } from '@mantine/core';
import { SettingsContext } from '../../../Contexts/DirectusSettings';
import { translate } from '../../../i18n/translate';
import DefaultInput from './DefaultInput';

export default class SelectDisplay extends DefaultInput {
  state= {
    extraFields:[]
  }
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),
      placeholder: props.field.meta.options?.placeholder,
      description: props.field.meta.note,
      required: props.field.meta.required,
      disabled: props.field.meta.readonly,
      clearable: props.field.meta.options?.allowNone,
      creatable: props.field.meta.options?.allowOther,
      icon: props.field.meta.options?.icon ? <span className='material-icons' style={{ fontSize: 20 }}>{props.field.meta.options.icon}</span> : undefined
    };
    return (
      <Select
        searchable={inputProps.creatable}
        data={[...this.state.extraFields,...props.field?.meta?.options?.choices?.map(el => ({ label: translate(el.text), value: el.value }))]}
        value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
        onChange={(e) => this.context.onChange(props.field.field, e)}
        onCreate={inputProps.creatable? (query)=>this.setState({extraFields:[{ value: query, label: query },...this.state.extraFields]}) :undefined}
        getCreateLabel={(query) => `+ ${query}`}
        {...inputProps}
        {...this.props.field.options}
      />
    );
  }
}
