import * as React from "react";
import { Center, Text,LoadingOverlay,Title, Card,ThemeIcon,Group } from '@mantine/core';
import { abbreviateNumber } from '@directus/shared/utils';
import { DirectusContext } from "../../../../Provider/Directus";
import { isNil } from 'lodash';

export default class Metric extends React.Component {
  
  static contextType = DirectusContext
  state={loading:true,data:[]}

  async componentDidMount() {
    let query = this.query(this.props.options)
    const {data} = await this.context.directus.items(query.collection).readByQuery(query.query)
    this.setState({data,loading:false})
  }
  metric = (data) => {
    if (!data || data.length === 0) return null;
    if (this.props.options.field) {
      
      if (this.props.options.function === 'first' || this.props.options.function === 'last') {
        if (typeof data[0][this.props.options.field] === 'string') {
          return data[0][this.props.options.field];
        } else {
          return Number(data[0][this.props.options.field]);
        }
      } else {
        return Number(data[0][this.props.options.function][this.props.options.field]);
      }
    } else {
      return Number(data[0][this.props.options.function]);
    }
  };

  displayValue = (data) => {
    const metric = this.metric(data)
    if (isNil(metric)) return null;
    if (typeof metric === 'string') {
      return metric;
    }
    if (this.props.options.abbreviate) {
      return abbreviateNumber(metric, this.props.options.decimals ?? 0);
    }
    return metric;
    // return (Number(metric.value), 'decimal', {
    //   minimumFractionDigits: this.props.options.decimals ?? 0,
    //   maximumFractionDigits: this.props.options.decimals ?? 0,
    // } );
  };
  
  query(options) {
		if (!options || !options.function) return;
		const isRawValue = ['first', 'last'].includes(options.function);
		const sort = options.sortField && `${options.function === 'last' ? '-' : ''}${options.sortField}`;
		const aggregate = isRawValue
			? undefined
			: {
					[options.function]: [options.field || '*'],
			  };

		const panelQuery = {
			collection: options.collection,
			query: {
				sort,
				limit: 1,
				fields: [options.field],
			},
		};

		if (options.filter && Object.keys(options.filter).length > 0) {
			panelQuery.query.filter = options.filter;
		}

		if (aggregate) {
			panelQuery.query.aggregate = aggregate;
			delete panelQuery.query.fields;
		}

		return panelQuery;
	}
  render() {
    if(this.state.loading) return <LoadingOverlay visible overlayBlur={2} />
    return (
      <Card withShadow withBorder sx={{height:"100%"}}>
        <Group noWrap position="apart">
          <div>
            <Text sx={{whiteSpace:"nowrap"}}  color="dimmed" transform="uppercase" weight={700} size="xs" >
              {this.props.name}
            </Text>
            <Text sx={{whiteSpace:"nowrap"}}  weight={700} size="xl">
              {this.props?.options?.prefix}{this.displayValue(this.state.data)}{this.props?.options?.suffix}
            </Text>
          </div>
          {this.props.icon && <ThemeIcon color="gray" variant="light" size={38} radius="md" >
            <span className='material-icons' style={{ color: this.props.color, fontSize: 20 }}>{this.props.icon}</span>
          </ThemeIcon>}
        </Group>
        <Text color="dimmed" size="sm" mt="md">
          {this.props.note}
        </Text>
      </Card>
    );
  }
}

Metric.defaultProps = {
  "id": "bb6d1638-eb9c-47a4-ae92-f049366b02be",
  "dashboard": "7adad88d-f981-4213-a9d4-a69f405c94e5",
  "name": "Fälle",
  "icon": "forum",
  "color": null,
  "show_header": true,
  "note": null,
  "type": "metric",
  "position_x": 29,
  "position_y": 1,
  "width": 16,
  "height": 6,
  "options": {
      "collection": "cases",
      "field": "id",
      "function": "count"
  },
}