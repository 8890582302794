import * as React from 'react';
import { Container, Title, Text, Skeleton } from '@mantine/core';
import DefaultShell from '../../Components/Shell';
import DirectusDashboard from '../../Components/Directus/Dashboard';
import { DirectusContext } from "../../Provider/Directus";

export default class Dashboard extends React.Component {
  static contextType = DirectusContext
  state={loading:true,me:{
    first_name:"Lorem",
    last_name:"Ipsumcontent"
  }}
  async componentDidMount() {
    this.setState({ loading:false, me:await this.context.directus.users.me.read() });
  }
  render () {
    return (
      <>
        <Container fluid >
          <div style={{marginTop:30}}>
            <Text><Skeleton width="200px"  visible={this.state.loading}>{this.state.me.first_name} {this.state.me.last_name}</Skeleton></Text>
            <Title>Willkommen zurück!</Title>
          </div>
          <DirectusDashboard />
        </Container>
      </>
    );
  }
}
