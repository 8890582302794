import metric from './Metric';
import list from './List';
import timeSeries from './TimeSeries';

const panels = {
  metric,
  list,
  "time-series":timeSeries
};

export default panels