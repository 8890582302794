import { JsonInput, Textarea } from '@mantine/core';
import DefaultInput from './DefaultInput';

export default class CodeDisplay extends DefaultInput {
  renderInput (props) {
    const inputProps = {
      label: this.renderLabel(props.field.field, props.field.meta?.translations),
      placeholder: props.field.meta.options?.placeholder,
      description: props.field.meta.note,
      required: props.field.meta.required,
      disabled: props.field.meta.readonly
    };
    if (props.field?.meta?.options?.language == 'JSON') {
      return (
        <JsonInput
          {...inputProps}
          {...this.props.field.options}
          value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
          onChange={(e) => this.context.onChange(props.field.field, e)}
          formatOnBlur
          autosize
          minRows={4}
        />
      );
    }
    return (
      <Textarea
        {...inputProps}
        {...this.props.field.options}
        value={this.context.values[props.field.field] === undefined ? (props.field.schema.default_value ? props.field.schema.default_value : '') : this.context.values[props.field.field]}
        onChange={(e) => this.context.onChange(props.field.field, e.target.value)}
        autosize
        minRows={4}
      />
    );
  }
}
