
import React from 'react';
export const defaultState = {
  auth_login_attempts: 25,
  auth_password_policy: null,
  basemaps: null,
  custom_css: null,
  default_language: 'en-US',
  id: 1,
  mapbox_key: null,
  module_bar: null,
  project_color: null,
  project_descriptor: null,
  project_logo: null,
  project_name: 'Directus',
  project_url: null,
  public_background: null,
  public_foreground: null,
  public_note: null,
  storage_asset_presets: null,
  storage_asset_transform: 'all',
  storage_default_folder: null,
  translation_strings: {
    test_dropdown_translation: { 'de-DE': 'Test Element' }
  }

}
export const SettingsContext = React.createContext(defaultState);
