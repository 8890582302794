import {Component, forwardRef} from 'react';
import { Card, Text, Button,  Center } from '@mantine/core';
import { DirectusContext } from '../../../Provider/Directus';

import FieldSet from '../../../Components/Directus/FieldSet';

export default class AddContact extends Component {

  static contextType = DirectusContext
  state={
    value: null,
    valid: false
  }

  onUpdate(value,valid){
    console.log(value)
    this.setState({value,valid})
  }

  render () {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Card withBorder shadow="xl" style={{width:400, maxWidth:"100%"}} >
          <Card.Section withBorder p="md">
            <Text size="sm" weight={700} >
              Kontakt erstellen
            </Text>
            <Text color="dimmed" size="xs">
              Ansprechpartner für E-Mails und Post
            </Text>
          </Card.Section>
          <Card.Section withBorder p="md">
            <FieldSet key={"contact-add"} collection={"contacts"} onChange={(value,valid)=>this.onUpdate(value,valid)}/>
            <Button disabled={!this.state.valid} onClick={()=>this.props.setState({step:3,contact:this.state.value})} fullWidth mt="md">Weiter</Button>
            <Button variant="outline" color="gray" onClick={()=>this.props.setState({step:3,contact:null})} fullWidth mt="md">Ohne Fortfahren</Button>
          </Card.Section>
        </Card>
      </Center>
    );
  }
}
