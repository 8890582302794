import * as React from 'react';
import { Container, Badge, Indicator, Text, Button } from '@mantine/core';
import moment from 'moment';
import DefaultShell from '../../Components/Shell';
import DirectusTable from '../../Components/Directus/Table';
import { Link } from 'react-router-dom';
import { IconStar,IconPlus} from '@tabler/icons';

export default class Cases extends React.Component {
  render () {
    return (
      <>
        <Container fluid>
          <DirectusTable
            collection='cases'
            horizontalSpacing='sm'
            verticalSpacing='sm'
            onClick={()=>console.log("test")}
            extraFields={[
              'user_created.last_name',
              'person.givenname',
              'location.review_count'
            ]}
            rightContainer={<div style={{marginBottom:16}}>
              <Button component={Link} to="/cases/add" leftIcon={<IconPlus />}>Hinzufügen</Button>
            </div>}
            fields={[
              {
                label: 'Phase',
                key: 'stage.name',
                sort: true
              },
              {
                label: 'Sterne',
                key: 'location.rating',
                sort: true,
                render: val => {
                  if (val.location) {
                    return (
<Indicator inline label={val?.location?.review_count} size={16}>
                      {(()=>{
                          if(val?.location?.rating > 4) return <><Badge  sx={{ paddingLeft: 4 }} leftSection={<IconStar size="10" />} color="green" radius="sm" variant="filled">{val?.location?.rating}</Badge></>
                          if(val?.location?.rating < 3) return <><Badge  sx={{ paddingLeft: 4 }} leftSection={<IconStar size="10" />} color="red" radius="sm" variant="filled">{val?.location?.rating}</Badge></>
                          return <><Badge  sx={{ paddingLeft: 4 }} leftSection={<IconStar size="10" />} color="yellow" radius="sm" variant="filled">{val?.location?.rating}</Badge></>
                      })()}
                    </Indicator>
);
                  }else { return '' ;}
                }
              },
              {
                label: 'Standort',
                key: 'location.title',
                sort: true
              },
              {
                label: 'Identität',
                key: 'person.surname',
                render: val => <Text>{val?.person?.givenname} {val?.person?.surname}</Text>,
                sort: true
              },
              {
                label: 'Status',
                key: 'status',
                sort: true
              },
              {
                label: 'Erstellt von',
                key: 'user_created.first_name',
                render: val => <Text>{val?.user_created?.first_name} {val?.user_created?.last_name}</Text>,
                sort: true
              },
              {
                label: 'Geändert am',
                key: 'date_updated',
                render: val => <Text>{val.date_updated ? moment(val.date_updated).format('DD.MM.YYYY') : ''}</Text>,
                sort: true

              },
              {
                label: 'Erstellt am',
                key: 'date_created',
                render: val => <Text>{moment(val.date_created).format('DD.MM.YYYY')}</Text>,
                sort: true
              }
            ]}
          />
        </Container>
      </>
    );
  }
}
