import * as React from 'react';
import { Card, Loader, Center, Stepper } from '@mantine/core';
import moment from 'moment';
import { Navigate } from "react-router-dom";
import { IconStar } from '@tabler/icons';
import SelectPlace from './SelectPlace';
import AddContact from './AddContact';
import AddReview from './AddReview';

import { DirectusContext } from '../../../Provider/Directus';
export default class AddCase extends React.Component {

  static contextType = DirectusContext
  state={
    step:1,
    navigate:false,
    loading:false,
    contact: null,
    place: null,
    finish: false
  }

  async uploadStart(){

    this.setState({loading:true})
    try{
      await this.uploadProcess()
      this.setState({loading:false,finish:true})
    }catch(e){ 
      console.log(e)
      this.setState({loading:false,error:true})
    }
  }

  async uploadProcess(){ 
      const result = await this.context.directus.transport.get(
        "scaleserp/valueserp",
        {params:{
          search_type: "place_details",
          data_cid: this.state.place.data_cid,
          hl: "de"
        }}
      )
      let location;
      try{
        location = await this.context.directus.items("locations").createOne({
          cid: result.raw.place_details.data_cid,
          address: result.raw.place_details.address,
          title: result.raw.place_details.title,
          rating: result.raw.place_details.rating,
          category: result.raw.place_details.category,
          review_count: result.raw.place_details.reviews,
          geo_coordinates: result.raw.place_details.gps_coordinates?JSON.stringify({
            "coordinates": [
              result.raw.place_details.gps_coordinates.latitude,
              result.raw.place_details.gps_coordinates.longitude
            ],
            "type": "Point"
          }):undefined,
          status:"published"
        })
      }catch(e){
        console.log(e)
        location = await this.context.directus.items("locations").updateOne(result.raw.place_details.data_cid,{
          address: result.raw.place_details.address,
          title: result.raw.place_details.title,
          rating: result.raw.place_details.rating,
          category: result.raw.place_details.category,
          review_count: result.raw.place_details.reviews,
          geo_coordinates: result.raw.place_details.gps_coordinates?JSON.stringify({
            "coordinates": [
              result.raw.place_details.gps_coordinates.latitude,
              result.raw.place_details.gps_coordinates.longitude
            ],
            "type": "Point"
          }):undefined,
          status:"published"
        })
      }

      
      const caseObj = await this.context.directus.items("cases").createOne({
        stage:"176c889d-c266-44fd-9d0a-837320ad0c7d",
        location: location.cid,
        person: (await this.context.directus.users.me.read()).persons[0]
      })

      for(let review of this.state.reviews){
        console.log()
        await this.context.directus.items("reviews").createOne({
          ...review,
          location: location.cid,
          post_after: moment(review.post_after).toISOString()
        })
      }

      if(this.state.contact){
        await this.context.directus.items("contacts").createOne({
          ...this.state.contact,
          case: caseObj.id
        })
      }
      console.log("DONE")
      // this.setState({navigate:true})
       
  }
  render () {
    return (
      <div style={{height:"100%"}}>

        <Card withBorder shadow="xl" 
            sx={(theme) => ({
              position: 'absolute',
              padding: 16,
              top:16,right:16,
              maxWidth: 600,
              width:"100%",
              overflow: 'hidden',
              zIndex:8000,
            })}
          >
          <Stepper iconSize={42} active={this.state.step-1}>
            <Stepper.Step label="Standort" description="Unternehmen" />
            <Stepper.Step label="Kontakt" description="Ansprechpartner" />
            <Stepper.Step label="Bewertungen" description="3. Stück" />
          </Stepper>
        </Card>
        {this.state.step === 1 ? (<SelectPlace setState={this.setState.bind(this)} />):""}
        {this.state.step === 2 ? (<AddContact setState={this.setState.bind(this)} />):""}
        {this.state.step === 3 ? (<AddReview uploadStart={this.uploadStart.bind(this)} setState={this.setState.bind(this)} />):""}
        {this.state.step === 4 ? (
          <Center style={{ width: "100%", height: "100%" }}>
            <Card withBorder shadow="xl" style={{width:400, maxWidth:"100%"}} >
            {this.state.loading && 
              <Center style={{ width: "100%", height: "100%" }}>
                <Loader size="lg" mr="lg" />
                Bitte warten...
              </Center>
            }
            {this.state.error && 
              <Center style={{ width: "100%", height: "100%" }}>
                Fehlgeschlagen!
              </Center>
            }
            {this.state.finish && 
              <Center style={{ width: "100%", height: "100%" }}>
                Erfolgreich!
              </Center>
            }
            </Card>
            {this.state.navigate && <Navigate to={"/cases/view/"+this.state.place.data_cid} replace={true} />}
        </Center>
        ):""
      }
      
      </div>
    );
  }
}
