import { Alert, Grid, Switch } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import AccordionDisplay from './Accordion';
import CodeDisplay from './Code';
import DatetimeDisplay from './Datetime';
import EditorDisplay from './Editor';
import GroupDisplay from './Group';
import GroupDetailDisplay from './GroupDetail';
import InputDisplay from './Input';
import SelectDisplay from './Select';
import SliderDisplay from './Slider';
import TagsDisplay from './Tags';
import SwitchDisplay from './Switch';
import SelectM2ODisplay from './SelectM2O';

const Displays = {
  not_supported: ({ field }) => field.meta.hidden ? null : <Grid.Col key={field.field} span={2}><Alert style={{ padding: '5px 5px 0 10px', marginTop: 15 }} key={field.field} variant='outline' color='yellow' icon={<IconAlertCircle size={16} />} title={`Feld-Typ wird nicht unterstützt! (${field.meta.interface})`} /></Grid.Col>,
  input: props => <InputDisplay key={props.field.field} {...props} />,
  'select-dropdown': props => <SelectDisplay key={props.field.field} {...props} />,
  'input-code': props => <CodeDisplay key={props.field.field} {...props} />,
  'input-multiline': props => <CodeDisplay key={props.field.field} {...props} />,
  'input-rich-text-html': props => <EditorDisplay key={props.field.field} {...props} />,
  tags: props => <TagsDisplay key={props.field.field} {...props} />,
  'group-raw': props => <GroupDisplay key={props.field.field} {...props} />,
  'group-accordion': props => <AccordionDisplay key={props.field.field} {...props} />,
  'group-detail': props => <GroupDetailDisplay key={props.field.field} {...props} />,
  slider: props => <SliderDisplay key={props.field.field} {...props} />,
  boolean: props => <SwitchDisplay key={props.field.field} {...props} />,
  'select-dropdown-m2o': props => <SelectM2ODisplay key={props.field.field} {...props} />,
  datetime: props => <DatetimeDisplay key={props.field.field} {...props} />

};
Displays.renderField = (field, context, options, fields, directus) => {
  if (field.meta.interface == 'list') console.log(field);
  if (!Displays[field.meta.interface]) return Displays.not_supported({ field });
  return Displays[field.meta.interface]({ field, context, options, fields, directus });
};
export default Displays
;
