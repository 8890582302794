
import { useState } from 'react';
import { useDirectus } from '../Provider/Directus';
import { Navbar, Divider, Center, Tooltip, UnstyledButton, createStyles, Stack } from '@mantine/core';
import {
  IconFingerprint,
  IconCalendarStats,
  IconList,
  IconStars,
  IconLogout,
  IconHome,
  IconActivityHeartbeat
} from '@tabler/icons';

import { Link } from 'react-router-dom';
import JobList from './Widgets/JobList';

function NavbarLink ({ icon: Icon, label, active, component, to }) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position='right' transitionDuration={0}>
      <UnstyledButton component={component} to={to} className={cx(classes.link, { [classes.active]: active })}>
        <Icon stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const mockdata = [
  { icon: IconHome, label: 'Home', link: '/' },
  { icon: IconList, label: 'Fälle', link: '/cases' },
  { icon: IconCalendarStats, label: 'Jobs', link: '/jobs' },
  { icon: IconStars, label: 'Bewertungen', link: '/reviews' },
  { icon: IconFingerprint, label: 'Accounts', link: '/accounts' }
];

export default function NavbarMinimalColored () {
  const { directus } = useDirectus();
  const onLogout = async () => {
    await directus.auth.logout();
    window.location.reload();
  };

  const links = mockdata.map((link, index) => (
    <NavbarLink
      {...link}
      component={Link} to={link.link}
      key={link.label}
    />
  ));

  return (
    <Navbar
      width={{ base: 80 }}
      p='md'
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[6]
      })}
    >
      <Center>
        <img alt='infinity' style={{ height: 50 }} src={require('../Assets/tcy-quadrat.png')} />
      </Center>
      <Navbar.Section grow mt={50}>
        <Stack justify='center' spacing={0}>
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section>
        <Stack justify='center' spacing={0}>
          <JobList />
          <Divider my="sm" />
          <NavbarLink onClick={onLogout.bind(this)} icon={IconLogout} label='Logout' />
        </Stack>
      </Navbar.Section>

    </Navbar>
  );
}

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.white,
    opacity: 0.85,

    '&:hover': {
      opacity: 1,
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
        0.1
      )
    }
  }

}));
