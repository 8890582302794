import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translations/en-US.json';
import translationDE from './translations/de-DE.json';

// the translations
const resources = {
  'en-US': {
    translation: translationEN
  },
  'de-DE': {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'de-DE',
    fallbackLng: 'en-US'
  });

export default i18n;
